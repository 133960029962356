<template>
    <el-dialog
        :title="$t('employee_details.exclude_from_client')"
        :visible.sync="modalAddExclude"
        :before-close="closeModal"
        width="600px"
        top="40px"
        :close-on-click-modal="false"
        @closed="$emit('closed')"
    >
        <div v-loading="$wait.is('loading.add_exclude')" class="flex justify-center my-5 px-12">
            <el-form ref="form" :model="formData" label-position="top" class="w-full">
                <el-form-item :label="$t('employee_details.reason')" class="w-full">
                    <ClientsDropdown
                        ref="clientsDropdown"
                        :clearable="false"
                        withoutQuerySearch
                        class="w-full"
                        @selected="clientSelectionChanged"
                    />
                </el-form-item>

                <el-form-item :label="$t('employee_details.reason')" class="w-full">
                    <el-input
                        v-model="formData.reason"
                        type="textarea"
                        :rows="3"
                    />
                </el-form-item>

                <p v-if="warningMessage" class="text-red-500 text-center">
                    {{ $t('employee_details.this_client_is_already_excluded_from_this_employee') }}
                </p>
            </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="closeModal">{{ $t('common.cancel') }}</el-button>
            <el-button type="primary" :disabled="addingBlocked" :loading="$wait.is('loading.add_exclude')" @click="add">
                {{ $t('common.add') }}
            </el-button>
        </span>
    </el-dialog>
</template>
<script>
export default {
    components: {
        ClientsDropdown: () => import(/* webpackChunkName: "ClientsDropdown" */ '@/components/ClientsDropdown'),
    },

    props: {
        modalAddExclude: {
            type:    Boolean,
            default: false,
        },
        employeeUuid: {
            type:    String,
            default: null,
        },
        excludesDataUuid: {
            type:    Array,
            default: () => [],
        },
    },

    data() {
        return {
            addingBlocked:  true,
            warningMessage: false,
            formData:       {
                client_uuid: null,
                reason:      '',
            },
        };
    },

    methods: {
        async add() {
            this.$wait.start('loading.add_exclude');

            await axios.post(`${process.env.VUE_APP_CORE_API_URL}/employees/employees/${this.employeeUuid}/excludes`, {
                client_uuid: this.formData.client_uuid,
                reason:      this.formData.reason,
            });

            this.$wait.end('loading.add_exclude');
            this.$emit('refresh');
            this.closeModal();
        },

        clientSelectionChanged(clientUuid) {
            if (this.excludesDataUuid.includes(clientUuid)) {
                this.addingBlocked = true;
                this.warningMessage = true;
                return;
            }
            this.addingBlocked = false;
            this.warningMessage = false;
            this.formData.client_uuid = clientUuid;
        },

        closeModal() {
            this.$refs.clientsDropdown.resetSelection();
            this.$emit('close');
        },
    },
};
</script>
